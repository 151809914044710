import React, { useState, useEffect, Suspense } from "react";
import { navigate, graphql, Link } from "gatsby";

// import BookingLayout from "../../components/BookingLayout";
import cityImg from "../images/location.svg";
import areaImg from "../images/area.svg";
import houseImg from "../images/house.svg";
import timeImg from "../images/time.svg";
import levelImg from "../images/level.svg";
import commercial from "../images/location.svg";

import locationImg from "../images/loc.svg";
// import LocationPicker from "react-location-picker";
import DatePicker from "react-datepicker";

import Select from "react-select";
import FadeIn from "react-fade-in";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Checkout from "./Checkout";

import loadable from "@loadable/component";
const BookingLayout = loadable(() => import("./BookingLayout"));

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const sty = (theme) => ({
  ...theme,

  colors: {
    ...theme.colors,
    primary25: "#fed0cd",
    primary: "#FB2717",
    primary75: "#fed0cd",
    primary50: "#fed0cd",
  },
});

const cityOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const NotAvailable = () => (
  <FadeIn className="bookgg">
    <h2>This Service Not Available</h2>
  </FadeIn>
);

const City = ({
  city,
  setCity,
  setIndex,
  cityData,
  index,
  setCommercialPricing,
}) => {
  const [error, setError] = useState("");
  const [cityarr, settCity] = useState([]);

  // console.log("RENDER")
  useEffect(() => {
    // console.log("logic run");
    let a = [];
    cityData.forEach((e) => {
      a.push({ value: e.city, label: e.city, price: e.CommercialPricing });
    });
    settCity(a);
  }, []);

  return (
    <FadeIn className="bookgg">
      <h2>Select Your City</h2>
      <div className="insideBook">
        <div>
          <Select
            isSearchable={false}
            value={city}
            onChange={(s) => {
              setCity(s);
              setCommercialPricing(s.price);
            }}
            options={cityarr}
            theme={sty}
          />
        </div>
        <div className="im">
          <img src={cityImg} width="100%" />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          city ? setIndex(index + 1) : setError("Please Select City");
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const Area = ({
  area,
  setArea,
  setIndex,
  areaData,
  updatePrice,
  totalPrice,
  index,
}) => {
  const [error, setError] = useState("");
  const [areaarr, setarea] = useState([]);
  // console.log("RENDER")
  useEffect(() => {
    // console.log("logic run");
    let a = [];
    areaData.forEach((e) => {
      a.push({ value: e.size, label: e.size });
    });
    setarea(a);
  }, []);

  return (
    <FadeIn className="bookgg">
      <h2>Select Your Area</h2>
      <div className="insideBook">
        <div>
          <Select
            isSearchable={false}
            value={area}
            onChange={(s) => {
              setArea(s);
              updatePrice(s);
            }}
            options={areaarr}
            theme={sty}
          />
          {totalPrice == 0 && area && (
            <p className="err">Service for this area not available</p>
          )}
        </div>
        <div className="im">
          <img src={areaImg} width="100%" />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          area && totalPrice != 0
            ? setIndex(index + 1)
            : setError("Please Select Area");
        }}
      >
        Next
      </div>

      <p className="err">{error}</p>
    </FadeIn>
  );
};

const CommercialArea = ({
  area,
  setArea,
  setIndex,
  areaData,
  updatePrice,
  totalPrice,
  index,
}) => {
  const [error, setError] = useState("");
  const [areaarr, setarea] = useState([]);
  // console.log("RENDER")
  // useEffect(() => {
  //   console.log("logic run")
  //   let a=[]
  //   areaData.forEach(e => {
  //     a.push({value: e.size, label: e.size })
  //   });
  //   setarea(a)
  // },[])

  return (
    <FadeIn className="bookgg">
      <h2>Describe your Commercial Area</h2>
      <div className="insideBook">
        <div>
          <textarea
            placeholder="Describe your area (ex. 1,400 sq feet office space...)"
            value={area.value}
            onChange={(x) => setArea({ value: x.target.value })}
          ></textarea>
        </div>
        <div className="im">
          <img src={areaImg} width="100%" />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          area ? setIndex(index + 1) : setError("Please Describe your Area");
        }}
      >
        Next
      </div>

      <p className="err">{error}</p>
    </FadeIn>
  );
};

const PersonalInfo = ({
  location,
  setLocation,
  phone,
  setPhone,
  name,
  setName,
  email,
  setEmail,
  setIndex,
  index,
}) => {
  const [error, setError] = useState("");

  const handleLocationChange = ({ position, address, places }) => {
    // Set new location
    // this.setState({ position, address });
  };

  return (
    <FadeIn className="bookgg">
      <h2>Personal Information</h2>
      <div className="insideBook">
        <div className="mob-full">
          <input
            className="personal"
            type="name"
            value={name}
            onChange={(x) => setName(x.target.value)}
            placeholder="Full Name"
          />
          <input
            className="personal"
            type="email"
            value={email}
            onChange={(x) => setEmail(x.target.value)}
            placeholder="Enter email"
          />
          <input
            className="personal"
            type="text"
            value={phone}
            onChange={(x) => setPhone(x.target.value)}
            placeholder="Enter phone number(without 91)"
          />
          <textarea
            placeholder="Full address..."
            value={location}
            onChange={(x) => setLocation(x.target.value)}
          ></textarea>
        </div>
        {/* <div className="im">
          <LocationPicker
            containerElement={ <div style={ {height: '100%'} } /> }
            mapElement={ <div style={ {height: '400px'} } /> }
            defaultPosition={{
  lat: 27.9878,
  lng: 86.9250
}}
            onChange={handleLocationChange}
          />
        </div> */}
      </div>
      <div
        className="nexbut"
        onClick={() => {
          if (!name || !location || !email || !phone) {
            setError("Please fill all details");
          }
          if (phone.length != 10) {
            setError("Phone Number should be of 10 Digit");
          } else if (!validateEmail(email)) {
            setError("Enter Correct Email");
          } else setIndex(index + 1);
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const ResCom = ({ res, setRes, setIndex, index }) => {
  const [error, setError] = useState("");
  return (
    <FadeIn className="bookgg">
      <h2>Select Your Residential/Commercial</h2>
      <div className="insideBook">
        <div>
          <div
            className={res == "RESIDENTIAL" ? "seel" : "raad"}
            onClick={() => setRes("RESIDENTIAL")}
          >
            RESIDENTIAL
          </div>
          <div
            className={res == "COMMERCIAL" ? "seel" : "raad"}
            onClick={() => {
              setRes("COMMERCIAL");
            }}
          >
            COMMERCIAL
          </div>
        </div>
        <div className="im">
          {res == "COMMERCIAL" ? (
            <img src={commercial} width="100%" />
          ) : (
            <img src={houseImg} width="100%" />
          )}
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          res
            ? setIndex(index + 1)
            : setError("Please Select Before Proceeding");
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const Size = ({ res, setRes, setIndex, index }) => {
  const [error, setError] = useState("");
  return (
    <FadeIn className="bookgg">
      <h2>Select Size of Location</h2>
      <div className="insideBook">
        <Select
          value={res}
          onChange={(s) => setRes(s)}
          options={cityOptions}
          theme={sty}
        />
        <div className="im">
          <img src={houseImg} width="100%" />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          res
            ? setIndex(index + 1)
            : setError("Please Select Size of Location");
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const Time = ({ res, setRes, date, setDate, setIndex, timeData, index }) => {
  const [error, setError] = useState("");

  const [timeArr, setTime] = useState([]);
  // console.log("RENDER")
  useEffect(() => {
    // console.log("logic run");
    let a = [];
    timeData.forEach((e) => {
      a.push({ value: e.time, label: e.time });
    });
    setTime(a);
  }, []);

  return (
    <FadeIn className="bookgg">
      <h2>Select Time & Date</h2>
      <p>Select you preferred time. We will send you finalize day and time.</p>

      <div className="insideBook">
        <div className="timecont">
          <Select
            isSearchable={false}
            placeholder="Select Time..."
            value={res}
            onChange={(s) => setRes(s)}
            options={timeArr}
            theme={sty}
          />

          <DatePicker
            className="datepick"
            onFocus={(e) => e.target.blur()}
            selected={date}
            minDate={new Date()}
            dateFormat="d MMMM , yyyy"
            onChange={(date) => setDate(date)}
            placeholderText="Select a date"
          />
        </div>

        <div className="im">
          <img src={timeImg} width="100%" />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          res && date ? setIndex(index + 1) : setError("Please Select Time");
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const Level = ({ res, setRes, setIndex, index }) => {
  const [error, setError] = useState("");
  return (
    <FadeIn className="bookgg">
      <h2>Select Level</h2>
      <div className="insideBook">
        <div>
          <div
            className={res == "PREMIUM" ? "seel" : "raad"}
            onClick={() => setRes("PREMIUM")}
          >
            PREMIUM
          </div>
          <div
            className={res == "ECONOMY" ? "seel" : "raad"}
            onClick={() => setRes("ECONOMY")}
          >
            ECONOMY
          </div>
        </div>
        <div className="im">
          <img src={levelImg} width="100%" />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          res
            ? setIndex(index + 1)
            : setError("Please Select Before Proceeding");
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const Prem = ({
  setPremium,
  premium,
  res,
  setRes,
  icon,
  data,
  setIndex,
  Pricings,
  index,
}) => {
  const [error, setError] = useState("");

  // console.log(data.title);
  // console.log(price)
  // console.log(data);
  return (
    <FadeIn className="bookgg">
      <div className="insideBook">
        <div className="mob-full">
          <h3 style={{ textAlign: "left" }}>
            {data.title} {!data.title.toLowerCase().includes('control')&&"Control"}
            <span style={{ color: "#FB2717" }}> {premium && `Premium`} </span>
            {/* <span>{data.area.value&&("("+data.area.value+")")}</span> */}
          </h3>
          <p className="price">
            Rs {data.res == "COMMERCIAL" ? data.CommercialPrice : Pricings}
          </p>
          <p className="pp">{data.location}</p>
          <p className="pp">{data.res}</p>
          <p className="pp">{data.area.value}</p>
          <p className="pp">
            Date/Time: {moment(data.date).format("DD/MMM/YY")} —{" "}
            {data.time.value}{" "}
          </p>

          <br />

          {data.res != "COMMERCIAL" && (
            <label className="container">
              <input
                type="checkbox"
                defaultChecked={premium}
                onChange={(_) => setPremium(!premium)}
              />
              Premium AMC (1 Year Coverage)
              <span className="checkmark"></span>
            </label>
          )}
        </div>
        <div className="im mob-hide">
          <img src={icon} width="90%" style={{ maxHeight: "200px" }} />
        </div>
      </div>
      <div
        className="nexbut"
        onClick={() => {
          setIndex(index + 1);
        }}
      >
        Next
      </div>
      <p className="err">{error}</p>
    </FadeIn>
  );
};

const Disclaimer = ({ data, setIndex, index }) => {
  const [error, setError] = useState("");
  return (
    <FadeIn className="bookgg">
      <p className="disclaimer">Covid-19 Guidelines of the Technichian</p>
      <br />
      <div className="disclaimerBod">
        <div className="page-body" dangerouslySetInnerHTML={{ __html: data }} />
      </div>
      <br />
      <br />
      <div
        className="nexbut"
        onClick={() => {
          setIndex(index + 1);
        }}
      >
        I Accept, Proceed
      </div>
    </FadeIn>
  );
};

const Payment = ({ res, setRes, icon, data, setIndex, index, setoffline }) => {
  const [error, setError] = useState("");
  // console.log(data);
  return (
    <FadeIn className="bookgg">
      <p className="disclaimer">Payment</p>
      <br />

      <br />
      <div
        className="nexbut"
        onClick={() => {
          setIndex(index + 1);
        }}
      >
        Pay Online
      </div>

      <div
        className="nexbut outer"
        onClick={() => {
          setIndex(index + 1);
        }}
      >
        I will Pay Offline
      </div>
    </FadeIn>
  );
};

function BookingBod({ data }) {
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [res, setRes] = useState("");
  const [size, setSize] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState(false);
  const [level, setLevel] = useState("");

  const [premium, setPremium] = useState(false);

  const [bonus, setBonus] = useState("");

  const [totalPrice, setTotalPrice] = useState([]);

  //section data

  //index for the page
  const [index, setIndex] = useState(0);

  let daa = data.strapiServices;

  //price array
  const Pricings = data.strapiBooking.Pricings;
  const [priceArr, setPriceArr] = useState([]);
  const [CommercialPrice, setCommercialPricing] = useState("");

  //back
  const [checkoutback, setcheckoutback] = useState(false);

  useEffect(() => {
    setPriceArr(Pricings.filter((v) => v.service.title === daa.title));
    //commenrcial Pricing
  }, []);

  // console.log(priceArr);

  const updatePrice = (a) => {
    if (res == "RESIDENTIAL")
      setTotalPrice(
        priceArr[0].areaPricing.filter((v) => v.locationSize === a.value)
      );
    else setTotalPrice(priceArr[0].areaPricing[0]);
  };

  //pricing array done

  return (
    <BookingLayout
      title={daa.title}
      back="/booking"
      index={index}
      setIndex={setIndex}
      imm={daa.icon.localFile.publicURL}
      checkoutback={checkoutback}
    >
      {/* 
      {totalPrice.length>0&&<h4>Total Price {totalPrice[0].economyPrice} {level}</h4>}
      {moment(date).format('DD/MMM/YY')}
       */}
      {index == 0 &&
        (priceArr.length > 0 ? (
          <City
            index={index}
            city={city}
            setCity={setCity}
            setIndex={setIndex}
            cityData={data.strapiBooking.Locations}
            setCommercialPricing={setCommercialPricing}
          />
        ) : (
          <NotAvailable />
        ))}
      {index == 1 && (
        <ResCom index={index} res={res} setRes={setRes} setIndex={setIndex} />
      )}
      {index == 2 &&
        (res == "RESIDENTIAL" ? (
          <Area
            index={index}
            area={area}
            setArea={setArea}
            setIndex={setIndex}
            areaData={data.strapiBooking.LocationSize}
            priceArr={priceArr[0].areaPricing}
            updatePrice={updatePrice}
            totalPrice={totalPrice.length}
          />
        ) : (
          <CommercialArea
            index={index}
            area={area}
            setArea={setArea}
            setIndex={setIndex}
            areaData={data.strapiBooking.LocationSize}
            priceArr={priceArr[0].areaPricing}
            updatePrice={updatePrice}
            totalPrice={totalPrice.length}
          />
        ))}
      {index == 3 && (
        <PersonalInfo
          index={index}
          location={location}
          setLocation={setLocation}
          phone={phone}
          setPhone={setPhone}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          setIndex={setIndex}
        />
      )}

      {/* {index == 4 && <Size  index={index} res={size} setRes={setSize} setIndex={setIndex} />} */}
      {index == 4 && (
        <Time
          index={index}
          res={time}
          setRes={setTime}
          date={date}
          setDate={setDate}
          setIndex={setIndex}
          timeData={data.strapiBooking.Timings}
        />
      )}
      {/* {index == 5 && <Level  index={index} res={level} setRes={setLevel} setIndex={setIndex} />} */}

      {index == 5 && (
        <Prem
          premium={premium}
          setPremium={setPremium}
          index={index}
          res={bonus}
          setRes={setBonus}
          icon={daa.icon.localFile.publicURL}
          data={{
            title: daa.title,
            level,
            size,
            date,
            time,
            city,
            area,
            res,
            location,
            CommercialPrice,
          }}
          setIndex={setIndex}
          Pricings={
            premium
              ? totalPrice[0].premiumPrice
              : totalPrice.length > 0
              ? totalPrice[0].economyPrice
              : 0
          }
        />
      )}

      {index == 6 && (
        <Disclaimer
          index={index}
          data={data.strapiTermsAndConditions.body}
          setIndex={setIndex}
        />
      )}

      {index == 7 && (
        <Checkout
          setcheckoutback={setcheckoutback}
          index={index}
          price={
            premium
              ? totalPrice[0].premiumPrice
              : totalPrice.length > 0
              ? totalPrice[0].economyPrice
              : 0
          }
          res={bonus}
          setRes={setBonus}
          logo={data.file.childrenImageSharp[0].fixed.src}
          icon={daa.icon.localFile.publicURL}
          CommercialPrice={CommercialPrice}
          data={{
            title: daa.title,
            name,
            level,
            phone,
            email,
            size,
            date,
            time,
            city,
            area,
            res,
            location,
            premium,
            CommercialPrice,
          }}
          setIndex={setIndex}
          safetyFees={data.strapiBooking.SafetyFee}
        />
      )}
      <p className="needhelp">
        Need Help? <Link to="/contact-us">Contact us directly</Link>
      </p>
    </BookingLayout>
  );
}

export default BookingBod;
