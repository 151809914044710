import React, { Component } from "react";
import FadeIn from "react-fade-in";
import moment from "moment";
import axios from "axios";
import done from "../images/done.jpeg";

export default class Checkout extends Component {
  state = {
    des:
      this.props.data.title +
      " control " +
      this.props.data.level +
      " ( " +
      this.props.data.area.value +
      " )",
    extra: this.props.safetyFees,
    amount: this.props.price,
    loading: false,
    OrderID: null,
    booking: 0,
    offline: -1,
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }

  order = (response) => {
    // alert(response.razorpay_payment_id)
    this.setState({ loading: true, OrderID: response.razorpay_payment_id });
    let query = {
      name: this.props.data.name,
      address: this.props.data.location,
      amount:
        this.props.data.res == "RESIDENTIAL"
          ? "₹" + this.state.offline == 0
            ? String(this.state.amount + this.state.extra) + " [PAID]"
            : String(this.state.amount + this.state.extra) + " [COD]"
          : "COMMERCIAL Book",
      email: this.props.data.email,
      phone: this.props.data.phone,
      time: this.props.data.time.value,
      premium: this.props.data.premium,
      type: this.props.data.res,
      service: this.props.data.title,
      city: this.props.data.city.value,
      size: this.props.data.area.value,
      OrderID: response.razorpay_payment_id,
      date: this.props.data.date,
    };

    console.log(query);

    axios
      .post("https://dashboard.pestgogo.com/orders", query)
      .then((x) => {
        console.log(x.data);
        console.log(this.props.setcheckoutback(true));
        this.setState({ booking: 2 });
      })
      .catch((x) => {
        this.setState({ booking: 3 });
      })
      .finally(() => {
        //  console.log(this.props.setcheckoutback(true))
        this.setState({ loading: false });
      });
  };

  openCheckout = () => {
    let options = {
      key: "rzp_test_IqYaLUJYlTuYgN",
      // key:'rzp_live_lHwcepLt8NOzvM',
      amount: (this.state.amount + this.state.extra) * 100, // 2000 paise = INR 20, amount in paisa
      name: "Pestgogo",
      description: this.state.des,
      image: this.props.logo,
      handler: (response) => {
        this.order(response);
      },
      prefill: {
        name: this.props.data.name,
        email: this.props.data.email,
        contact: this.props.data.phone,
      },
      notes: {
        address: this.props.data.location,
        timings: this.props.data.time.value,
        date: moment(this.props.data.date).format("DD/MMM/YY"),
        service: this.props.data.title,
      },
      theme: {
        color: "#FB2717",
      },
    };

    let rzp = new window.Razorpay(options);
    console.log(rzp);
    rzp.open();
  };

  render() {
    return (
      <div>
        {this.state.booking == 2 && (
          <FadeIn className="bookgg bookggg">
            <img src={done} alt="" width={200} />

            <p style={{ fontSize: 16 }}>Booking ID: {this.state.OrderID}</p>
            <p>We will send you SMS/Email of your booking soon.</p>
          </FadeIn>
        )}

        {this.state.booking == 3 && (
          <FadeIn className="bookgg bookggg">
            {/* <img src={done} alt="" width={200}/> */}

            {/* <p style={{fontSize:16}}>Booking ID: {this.state.OrderID}</p> */}
            <p>
              Booking Failed. Please Try Again or contact us on{" "}
              <a href="https://api.whatsapp.com/send?phone=919560450376&text=Hello">
                Whatsapp
              </a>
            </p>
          </FadeIn>
        )}

        {!this.state.loading && this.state.booking == 0 && (
          <FadeIn className="bookgg">
            <p className="disclaimer">Payment </p>

            {this.props.data.res == "RESIDENTIAL" ? (
              <>
                <table className="tabb">
                  <tr>
                    <td>Item Total</td>
                    <td>Rs {this.props.price}</td>
                  </tr>
                  <tr>
                    <td>Safety & Hygiene fee</td>
                    <td>Rs {this.state.extra}</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th>Rs {this.props.price + this.state.extra}</th>
                  </tr>
                </table>
                <br />
                <div
                  className="nexbut"
                  onClick={() => {
                    this.openCheckout();
                    this.setState({ offline: 0 });
                  }}
                >
                  Pay Online
                </div>

                <div
                  className="nexbut outer"
                  onClick={() => {
                    {
                      this.setState({ booking: 1, offline: 1 });
                    }
                  }}
                >
                  I will Pay Offline
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <table className="tabb">
                  <tr>
                    <td>Item Total</td>
                    <td>Rs {this.props.CommercialPrice}</td>
                  </tr>
                  <tr>
                    <td>Safety & Hygiene fee</td>
                    <td>Rs {this.state.extra}</td>
                  </tr>
                </table>
                <p className="nott">
                  {" "}
                  Final Amount will calculated after area survey
                </p>
                <div
                  className="nexbut"
                  onClick={() => {
                    {
                      this.setState({ booking: 1 });
                    }
                  }}
                >
                  Booking Now
                </div>
              </div>
            )}
          </FadeIn>
        )}

        {!this.state.loading && this.state.booking == 1 && (
          <FadeIn className="bookgg">
            <p className="disclaimer">Are you sure confirm Booking? </p>
            <div
              className="nexbut"
              onClick={() => {
                let randLetter = String.fromCharCode(
                  65 + Math.floor(Math.random() * 26)
                );
                let uniqid = {
                  razorpay_payment_id: randLetter + Date.now(),
                };

                this.order(uniqid);
              }}
            >
              Yes
            </div>

            <div
              className="nexbut outer"
              onClick={() => {
                {
                  this.setState({ booking: 0 });
                }
              }}
            >
              No
            </div>
          </FadeIn>
        )}

        {this.state.loading && (
          <div className="bookgg bookggg">
            <div className="loader"></div>
          </div>
        )}
      </div>
    );
  }
}
